import { graphql, useStaticQuery } from 'gatsby';
import { MapPinLine, ChatCenteredDots, Users, Envelope } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import './nav.scss';
import logo from '../assets/images/logo.svg';
import logoWhite from '../assets/images/logo-white.svg';

interface HeaderData {
  strapiHeader: {
    ctaText: string;
  };
}

export const Nav: React.FC = () => {
  const data: HeaderData = useStaticQuery(query);
  const header = data.strapiHeader;
  const [isTabletOpened, setIsTabletOpened] = useState(false);
  const [displayMainCta, setDisplayMainCta] = useState(false);

  function handleLogoClick() {
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
    document.documentElement.scrollTo({ top: 0 });
    setIsTabletOpened(false);
  }

  function handleHamburgerClick() {
    setIsTabletOpened(!isTabletOpened);
  }

  function handleTabletMenuItemClick() {
    setIsTabletOpened(false);
  }

  function scrollChange() {
    setDisplayMainCta(window.scrollY > 400);
  }

  const navItems = [
    { icon: MapPinLine, href: '#carte', text: 'La carte' },
    { icon: ChatCenteredDots, href: '#avis', text: 'Avis' },
    { icon: Users, href: '#equipe', text: "L'équipe" },
  ];

  useEffect(() => {
    window.addEventListener('scroll', scrollChange);
  });

  return (
    <header
      className={'navbar' + (isTabletOpened ? ' navbar--tablet-opened' : '')}
    >
      <nav>
        <div className="navbar__main-wrapper">
          <div className="navbar__main">
            <div className="navbar__logo-container" onClick={handleLogoClick}>
              <img
                className="navbar__logo"
                src={isTabletOpened ? logoWhite : logo}
                alt="Uppy"
              />
              <div className="navbar__logo-catch">
                <span className="navbar__logo-catch__bar">|</span> By L&amp;A -
                Géomètres-Experts
              </div>
            </div>
            {navItems.map((item) => (
              <a
                key={item.href}
                href={item.href}
                className="navbar__desktop-elem navbar__menu-item"
              >
                {item.text}
              </a>
            ))}
            <a
              href="/la-carte"
              className={
                'navbar__desktop-elem uppy-button uppy-button--primary navbar__main-cta' +
                (!displayMainCta ? ' navbar__main-cta--hidden' : '')
              }
            >
              <MapPinLine className="uppy-button__icon" />
              {header.ctaText}
            </a>
            <div className="navbar__mobile-elem" onClick={handleHamburgerClick}>
              <div className="navbar__hamburger">
                <div className="navbar__hamburger__in navbar__hamburger__in--1"></div>
                <div className="navbar__hamburger__in navbar__hamburger__in--2"></div>
                <div className="navbar__hamburger__in navbar__hamburger__in--3"></div>
                <div className="navbar__hamburger__in navbar__hamburger__in--4"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar__tablet">
          <div className="navbar__tablet-in">
            <div className="navbar__tablet__nav">
              {navItems.map((item) => (
                <div
                  key={item.href}
                  className="navbar__tablet__nav__item"
                  onClick={handleTabletMenuItemClick}
                >
                  <item.icon className="navbar__tablet__nav__item__icon" />
                  <a href={item.href}>{item.text}</a>
                </div>
              ))}
            </div>
            <div
              className="navbar__tablet__cta"
              onClick={handleTabletMenuItemClick}
            >
              <a href="#contact" className="uppy-button uppy-button--primary">
                <Envelope className="uppy-button__icon" /> Contacter Uppy
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

const query = graphql`
  query {
    strapiHeader {
      ctaText
    }
  }
`;
