import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import './footer.scss';

import logoWhite from '../assets/images/logo-white.svg';
import geoExp from '../assets/images/partners/geo-exp.png';
import lEtA from '../assets/images/partners/l-et-a.png';
import rics from '../assets/images/partners/rics.png';

interface FooterData {
  strapiFooter: {
    catchPhrase: string;
  };
}

export const Footer: React.FC = () => {
  const data: FooterData = useStaticQuery(query);
  const footer = data.strapiFooter;

  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__brand">
          <img src={logoWhite} alt="Uppy" />
          <div className="footer__brand__catch">{footer.catchPhrase}</div>
          <div className="footer__brand__founder">
            <span className="footer__brand__founder__bar"> | </span>
            Un produit Lanquetin &amp; Associés
            <span className="footer__brand__founder__bar"> | </span>
          </div>
        </div>
        <div>
          <div className="footer__partners">
            <img
              src={lEtA}
              alt="Géometre Expert"
              className="footer__partners__partner"
            />
            <img
              src={geoExp}
              alt="Lanquetin et Associés"
              className="footer__partners__partner"
            />
            <img src={rics} alt="RICS" className="footer__partners__partner" />
          </div>
          <a href="" className="footer__cgu-link">
            Voir les conditions générales d’utilisation
          </a>
        </div>
        <div className="footer__chab"></div>
      </div>
    </footer>
  );
};

const query = graphql`
  query {
    strapiFooter {
      catchPhrase
    }
  }
`;
