import React from 'react';
import { Nav } from './nav';
import { Seo } from './seo';
import { ISeo } from '../models/seo';
import { Footer } from './footer';

interface LayoutProps {
  children: React.ReactNode;
  seo: ISeo;
}

export const Layout: React.FC<LayoutProps> = ({ children, seo }) => {
  return (
    <div className="layout">
      <Seo seo={seo} />
      <Nav />
      <main className="main">{children}</main>
      <Footer />
    </div>
  );
};
